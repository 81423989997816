.levelSelector {
  margin: 0 12px 0 12px;
  padding: 0.5em;
}

.sokobanGrid {
  align-items: center;
  display: flex;
  flex-direction: column;
  overscroll-behavior-y: contain;
  padding: 50px 0 50px 0;
}

.sokobanGrid.gameOver {
  opacity: 0.6;
}

.sokobanRow {
  display: flex;
}

.sokobanCell {
  align-items: center;
  border: 2px solid gray;
  border-radius: 3px;
  cursor: pointer;
  display: flex;
  font-size: 1.5rem;
  font-weight: 700;
  height: 2.8rem;
  justify-content: center;
  line-height: normal;
  margin: 0.1rem;
  text-transform: uppercase;
  width: 2.8rem;
}

.sokobanRules .sokobanCell {
  border: 1px solid gray;
  display: inline-block;
  font-size: 1rem;
  height: 1.3em;
  text-align: center;
  width: 1.3em;
  vertical-align: middle;
}

.sokobanRules .sokobanInnerCell {
  display: inline-block;  
  width: 1.3em;
  height: 1.3em;
}

.sokobanCell.hover {
  height: 3.0rem;
  margin: 0rem;
  width: 3.0rem;
}

.sokobanCell.selected {
  border-color: red;
}

.sokobanCell.block,
.sokobanCell.block.goal {
  background: rgba(0, 0, 0, 0.9);
}

.sokobanCell.block .sokobanInnerCell,
.sokobanCell.block.goal .sokobanInnerCell {
  background: 
    linear-gradient(to top right,
        rgba(0,0,0,0) 0%,
        rgba(0,0,0,0) calc(50% - 2px),
        rgba(255,255,255,1) 50%,
        rgba(0,0,0,0) calc(50% + 2px),
        rgba(0,0,0,0) 100%),
    linear-gradient(to top left,
        rgba(0,0,0,0) 0%,
        rgba(0,0,0,0) calc(50% - 2px),
        rgba(255,255,255,1) 50%,
        rgba(0,0,0,0) calc(50% + 2px),
        rgba(0,0,0,0) 100%);
  color: rgba(0, 0, 0, 0);
  height: 100%;
  width: 100%;
}

.stepCount {
  color: rgba(0,102,255,.5);
  font-size: 1em;
  font-weight: 700;
  margin: 0 8px 0 8px;
  vertical-align: middle;
}

.sokobanCell.goal {
  background: rgba(51, 170, 51, 0.4);
}

.sokobanCell.flag,
.sokobanCell.flag.goal {
  background: rgba(170, 51, 51, 0.4);
}

.footerButton {
  vertical-align: top;
}

.sokobanPack {
  margin-bottom: 32px;
}

.sokobanLevelPreview .sokobanLevelOverlay {
  display: flex;
  height: 100%;
  padding: 0 0 96px 0;
  position: absolute;
  width: 100%;
}

.sokobanLevelPreview.solved .sokobanGrid{
  opacity: 0.5;
}

.sokobanLevelPreview .checkmark {
  margin: auto;
  transform: scale(2.0);
}

.sokobanLevelCarousel .sokobanHeader {
  text-decoration-line: underline;
}

.sokobanLevelCarousel .sokobanGrid {
  margin: auto;
  padding: 0 0 68px 0;
}

.sokobanLevelCarousel .sokobanLevelPreview {
  display: flex; /* coupled with auto margin of child for centering */
  height: 250px;
  margin-bottom: 48px;
}

.sokobanLevelCarousel .spacer {
  display: inline-block;
  width: 12px;
}

.sokobanLevelCarousel .sokobanCell {
  border: 1px solid gray;
  border-radius: 1px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 400;
  height: 1.4rem;
  margin: 0.05rem;
  width: 1.4rem;
}

.sokobanListTable {
  display: inline-table;
  max-width: 480px;
  text-align: center;
  vertical-align: middle;
}

.sokobanNavbar .dropdown-toggle:after {
  border: 0;
  content: "\2261";
}

.puzzleExpiry {
  color: red;
  font-size: 0.8rem;
  margin-top: -10px;
}
.checkmark {
  border-radius: 50%;
  color: #fff;
  cursor: default;
  display: inline-block;
  height: 1.4rem;
  width: 1.4rem;
}

.checkmark.green {
  background: rgba(51, 170, 51, 0.8);
}

.checkmark.yellow {
  background: rgba(251, 201, 51, .8);
}

.checkmark.red {
  background: rgba(170, 51, 51, 0.8);
}

.checkmark.gray {
  background: rgba(51, 51, 51, 0.6);
}


.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.songLyrics,
.songInfo {
    position: absolute;
    display: flex;
    justify-content: center;
    height: 100%;
    width: 100%;
    z-index: 3;
    color: white;
    pointer-events: none;
}

.songInfo {
    align-items: start;
}

.songLyrics {
    align-items: end;
    padding-bottom: 40px;
}

.lyrics {
    animation: melt 1s 1 linear;
}

@keyframes showLyrics {
    0% {
	opacity: 0;
    }
    100% {
	opacity: 1;
    }
}



.melting-text-container {
    position: absolute;
    overflow: hidden;
    z-index: 5;
    transform: translateY(-200px);
    opacity: 1.0;
    pointer-events: none;
}

.melting-text {
    font-size: 6rem;
    font-weight: bold;
    text-transform: uppercase;
    color: #fff;
    position: relative;
    animation: melt 1s 1 ease-in-out;
    background: linear-gradient(90deg, #f0f0aa, #ffcdc4, #ffffff);
    -webkit-background-clip: text;
    color: transparent;
    opacity: 0;
}

.melting-text::before,
.melting-text::after {
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, #f0f0aa, #ffcdc4, #ffffff);
    -webkit-background-clip: text;
    color: transparent;
    z-index: -1;
    transform: scaleY(1);
    opacity: 0.9;
    animation: drip 2s 1 ease-in-out;
    pointer-events: none;
}

.melting-text::after {
    filter: blur(10px);
    opacity: 1.0;
}

/* Keyframes for melting effect */
@keyframes melt {
    0%, 100%{
	opacity: 0;
        transform: translateY(0);
    }
    25%, 70% {
	opacity: 0.9;
        transform: translateY(1px);
    }
}

@keyframes drip {
    0%, 100% {
        transform: scaleY(1);
        opacity: 0.5;
    }
    25%, 70% {
        transform: scaleY(1.2);
        opacity: 0.85;
    }
}






\



















.feedbackText p {
    font-family:Bungee;
    transition: all ease-out .5s;
    color: transparent;
    text-shadow: 0 0 0px #e78383;
    font-size: 6rem;
    padding: 0 1rem;
    overflow: hidden;
    display: block;
    position: relative;
    cursor: pointer;
}

.feedbackText p:before {
    content: "";
    width: 50%;
    position: absolute;
    border-bottom: solid 8px #fff;
    left: -50%;
    top: 50px;
    transition: transform 0.5s cubic-bezier(1, -0.04, 0, 1.16);
}

.feedbackText p span {
    display: block;
    position: absolute;
    height: 50%;
    width: 100%;
    overflow: hidden;
}

.feedbackText p span:first-child:before,
.feedbackText p span:last-child:before {
    content: attr(data-text);
    color: #fff;
    display: block;
    transition: ease-out all 0.3s -0.1s;
}

.feedbackText p span:first-child {
    top: 0;
}

.feedbackText p span:last-child {
    bottom: 0;
}

.feedbackText p span:first-child:before {
    transform: translateY(41px);
}

.feedbackText p span:last-child:before {
    transform: translateY(-95px)
}

.feedbackText p:hover {
    transition: all ease-out 0s;
    text-shadow: none;
}

.feedbackText p:hover:before {
    transform: translateX(42rem);
}

.feedbackText p:hover span:first-child:before,
.feedbackText p:hover span:last-child:before {
    transition: ease-out all 0.3s;
    transition-delay: .3s;
}

.feedbackText p:hover span:first-child:before {
    transform: translateY(0);
}

.feedbackText p:hover span:last-child:before {
    transform: translateY(-58px);
}

.App {
  font-family: Calibri, Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif;
  text-align: center;
}

html, body, .App, .App>div {
  height: 100%;
  width: 100%;
}

.AppBody {
  height: calc(100% - 56px); /* compensate for header height */
  overflow: scroll;
  position: fixed;
  width: 100%;
}

.AppBody a,
.modal-body a {
  text-decoration: none;
}

.AppContainer {
  height: 100%;
  padding: 12px 8px 0;
}

.flatdoglogo,
.palefirelogo,
.chestnutlogo {
    max-width: 100%;
    max-height: 100vh;
    margin: auto;
    transform: translate(-50%);
}

.App .ImgContainer {
    background-color: black;
    height: 100vh;
}

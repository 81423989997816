.puzzleView {
  margin: auto;
}

.puzzleContainer {
  border: 1px solid #ced4da;
  border-radius: 8px;
  display: flex;
  margin-bottom: 12px;
  margin-top: 8px;
  min-height: 400px;
}

.puzzlePack {
  margin: 8px auto;
}

.puzzlePackView {
  height: 100%;
}

.puzzleCarousel .carousel-control-next,
.puzzleCarousel .carousel-control-prev {
  width: 5%;
}

.puzzleCarousel .carousel-inner {
  height: 100%;
  left: 5%;
  overflow: scroll;
  padding-bottom: 32px;
  right: 5%;
  width: 90%;
}

.puzzleCarousel .carousel-indicators {
  background-image: linear-gradient(to top, white, white, rgba(255, 255, 255, 0.0));
  margin: 0;
  z-index: 3;
}

.puzzleCarousel {
  height: calc(100% - 62px); /* compensate for .puzzlePackHeader's height */
}

.puzzlePackHeader {
  margin: 0;
  height: 62px;
  max-height: 62px;
  padding: 0;
  white-space: nowrap;
}

.loginRequiredFooter {
  font-size: 1.1rem;
  font-weight: bold;
}

.loginRequiredFooter svg {
  margin-right: 0.5rem;
}

.navbar {
  /* beat the offcanvas z-index but lose to modal dialog*/
  z-index: 1046; 
}

.navbar .navbar-toggler-icon {
  margin-right: 4px;
  margin-top: -4px;
}

a svg {
  margin-right: 0.4rem;
  margin-top: -0.2rem;
}

:root {
    --note-w: 150px;
    --meter-h: 75vh;
    --meter-w: 64px;
    --side-w: 100px;
    --orbit-r: 960px;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.gameLayout {
    font-family: "JetBrains Mono", "Fira Code", "Cascadia Code", "Source Code Pro", "Inconsolata", "Monaco", "Courier New", "Helvetica";
    /* background: #102e30; */
    background: black;
    min-height: 100vh;
    max-width: 100vw;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: flex-start;
}

.gameScreen {
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 0 0 calc(100% - 2 * var(--side-w));
}

.rhythmQR {
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    justify-content: start;
}

.rhythmQR .qrCanvas {
    width: 196px;
    height: 196px;
    opacity: 0.8;
    margin: 12px;
    max-width: 20vh;
    max-height: 20vh;
}

.overlayScreen {
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: 0;
}

.flash {
    animation: flash 0.25s linear 1;
}

.feedbackText {
    font-size: 64px;
    color: white;
    font-weight: 700;
    z-index: 10;
}

.bigcircle, .circle {
    position: absolute;
    border-radius: 50%;
    z-index: 3;
    display: flex;
    justify-content: center;
    align-items: center;    
}

.bigcircle {
    width: var(--orbit-r);
    height: var(--orbit-r);
}

.bigcircle.flash1, 
.bigcircle.flash0 {
    animation: bigflash 0.25s linear 1;
}


.circle {
    width: var(--note-w);
    height: var(--note-w);
    transform: translate(var(--beat-pos-x), var(--beat-pos-y));
    outline: 12px solid #00eeff;
}

/**
 * The text within each beat.
 */
.circle .rhythmClue,
.songClue {
    position: absolute;
    font-size: 96px;
    color: white;
}

.songClue.giantClue {
    font-weight: 100;
    font-size: 300px;
    font-family: system-ui;
    transform: translate(0px, 0px);
}

.notes {
    position: absolute;
    height: var(--note-w);
    width: var(--note-w);
    display: flex;
    z-index: 1;
    justify-content: center;
    align-items: center;
}

.note {
    position: absolute;
    transform: translate(var(--beat-pos-x), var(--beat-pos-y));
    width: 140px;
    height: 140px;
    background: #4c00b0;
    opacity: 0.7;
    border-radius: 50%;
    /* animation: orbit 4s linear infinite; /* cheating css animation */
}

.bigcircle span,
.circle span {
    position: absolute;
    width: 100%;
    height: 100%;
    background: inherit;
    border-radius: inherit;
    border: inherit;
}

.bigcircle span {
    outline: 30px solid white;
    opacity: 0;
}

.circle span {
    outline: inherit;
    opacity: 0.8;
}

.circle .blocker {
    transform: scale(0.8);
    z-index: 4;
    background: #102e30;
    opacity: 1.0;
}

.circle.show {
    /* animation: fadein 0.5s linear 1; */
    opacity: 1;
    transition: 2s;
}

.circle.hide {
    /* animation: fadeout 0.5s linear 1; */
    opacity: 0;
    transition: 0.2s;
}



.bigcircle .pulse0 {
    animation: pulseAnimate3 0.2s linear 1;
}
.bigcircle .pulse1 {
    animation: pulseAnimate4 0.2s linear 1;
}

.pulse, .pulse0 {
    animation: pulseAnimate 0.2s ease-out 1;
}

.pulse1 {
    animation: pulseAnimate2 0.2s ease-out 1;
}

@keyframes pulseAnimate3 {
    0% {
	opacity: 1.0;
    }
    75% {
	opacity: 0;
	transform: scale(1.05);
    }
}

@keyframes pulseAnimate4 {
    0% {
	opacity: 1.0;
    }
    75% {
	opacity: 0;
	transform: scale(1.05);
    }
}


@keyframes pulseAnimate {
    100% {
	opacity: 0.1;
	transform: scale(1.3);
    }
}

@keyframes pulseAnimate2 {
    100% {
	opacity: 0.1;
	transform: scale(1.3);
    }
}


@keyframes bigflash {
   0%, 100% {
      opacity: 0;
   }
   35%, 65% {
      opacity: 1.0;
   }
}

@keyframes flash {
   0%, 100% {
      opacity: 0;
   }
   35%, 65% {
      opacity: 0.1;
   }
}

@keyframes fadein {
    from {
	opacity: 0;
    }
    to {
	opacity: 1;
    }
}

@keyframes fadeout {
    from {
	opacity: 1;
    }
    to {
	opacity: 0;
    }
}

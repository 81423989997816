.App .random-outer-container .random-container {
    background-color: black;
    height: 100vh;
}

.grow { 
    transition: all .2s ease-in-out;
}

.growdiv {
   animation: textgrowth 1s infinite alternate;
}

@keyframes textgrowth {
    0% {
        transform: scale(1.0);
        -webkit-transform: scale(1.0);
    }
    100% {
        transform: scale(1.2);
        -webkit-transform: scale(1.2);
    }
}

.centered-element {
    font-size: 30em;
    font-family: monospace;
    font-weight: bolder;
    letter-spacing: -4rem;
    color: antiquewhite;
    
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
}

.randomLogo {
    display: inline-block;
    position: absolute;
    bottom: 0px;
    left:50%;
}

.logo-chestnuters {
    transform: translate(-165%, -5%);
    z-index: 3;
}

.logo-palefire {
    left:50%;
    transform: translate(-50%, 0%);
    z-index: 1;
}

.logo-flatdog {
    transform: translate(35%, 10%);
    z-index: 2;
}

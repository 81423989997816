.puzzleBody {
  font-size: 1.2rem;
  margin: auto;
  padding: 48px 12px 12px 12px;
}

.puzzleTitle.solvedPuzzle {
  background: rgba(51, 170, 51, 0.8);
}

.puzzleTitle {
  background: rgba(0,0,0,0.6);
  border-radius: 4px;
  color: white;
  font-weight: 700;
  padding: 0 8px;
  position: absolute;
  margin: 8px 8px;
}

.puzzleImage {
  max-width: 100%;
}

.puzzleTable {
  border-collapse: collapse;
  display: table;
  font-size: 1.2rem;
  margin: auto;
}

.puzzleCrosswordGrid .puzzleTableCell{
  font-size: 0.75rem;
  text-align: left;
  vertical-align: top;
  padding-left: 2px;
}

.puzzleTableRow {
  display: table-row;
}

.puzzleTableCell {
  border: 2px solid gray;
  display: table-cell;
  height: 1.8rem;
  vertical-align: middle;
  width: 1.8rem;
}

.puzzleClueList {
  margin-right: 8px;
  margin-left: 8px;
  min-width: 300px;
  text-align: left;
}

.puzzleBody .puzzleClueList {
  max-width: 500px;
}

.puzzleFlex {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.puzzleTableCell.fill {
  background-color: gray;
}

.puzzleTableCell.block {
  border: none;
}

.puzzleTopFloatContainer {
  position: absolute;
  margin: 8px;
  right: 0;
}

.puzzleHintContainer .puzzleHintIcon {
  opacity: 0.5;
}

.puzzleHintContainer:hover .puzzleHintIcon {
  opacity: 0.9;
}

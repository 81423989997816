.notificationOptions .form-switch {
    display: flex;
}

.notificationOptions .form-switch .form-check-label {
    padding-left: 12px;
}

.notificationOptions .secondLevel {
    padding-left: 3.5em;
}

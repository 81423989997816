
.resultModal .modal-content {
  background-color: rgba(0, 0, 0, 0);  
  border:none;
  display: flex;
  height: 400px;
  margin-top: 150px;
  text-align: center;
}

.resultModal.win {
  color: rgba(51, 222, 51, 0.9);
}

.resultModal.lose {
  color: rgba(255, 60, 60, 0.9);
}

.resultIconContainer {
  height: 250px;
}

.resultModal.win .resultIconContainer {
  padding-top: 56px;
}

.resultText {
  height: 150px;
  font-size: 3rem;
  font-weight: 900;
}

.resultWithNotes .resultText {
  font-size: 1.5rem;
}

.resultModal.win .resultIcon {
  border: 28px solid rgba(51, 222, 51, 0.9);
  border-radius: 50%;
  display: block;
  height: 10rem;
  margin: auto;
  width: 10rem;
}

.resultModal.lose .resultIcon:after {
  content: "\d7";
  font-size: 22rem;
  line-height: 200px;
}

.resultModal .puzzleFooter {
  color: white;
  font-size: 1.3rem;
}
.pastEvent {
    opacity: 0.75;
}

.shopViewGroup {
    margin-top: 12px;
}

.apparelImage {
    max-width: 160px;
}

.apparelCard {
    margin-top: 8px;
    margin-bottom: 8px;
    text-align: left;
}

.cartTitle {
    text-align: left;
    margin-top: 24px;
}

.selected.colorPatchBox,
.selected.sizeBox {
    border-color: darkseagreen;
}

.selected.sizeBox {
    background-color: rgba(0,200,0,0.05);
}

.itemPrice {
    font-size: 1.25em;
    font-weight: bold;
}

.colorPatchBox,
.sizeBox {
    display: inline-block;
    margin-right: 4px;
    width: 24px;
    height: 24px;
    border: 2px solid grey;
}

.itemLabel {
    line-height: 2em;
}

.itemSelect {
    margin-top: 4px;
}

.sizeBox {
    border-radius: 10%;
    padding: 0;
    font-size: 0.85em;
    text-align: center;
}

.colorPatchBox {
    border-radius: 50%;
}

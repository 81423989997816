:root {
    --button-w: 500px;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    min-height: 100vh;
    min-height: -webkit-fill-available;
}

html {
     height: -webkit-fill-available;
}

.rhythmClientScreen {
    background: black;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}


.overlayButton {
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 999; /* make sure it's on top to be clickable */
}

.rhythmButton {
    position: absolute;
    border-radius: 50%;
    z-index: 4; 
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 80vw;
    max-height: 80vw;

    width: var(--button-w);
    height: var(--button-w);
    outline: 12px solid #4c00b0;
}

.rhythmButton span {
    position: absolute;
    width: 100%;
    height: 100%;
    background: inherit;
    border-radius: inherit;
    outline: inherit;
    opacity: 0.8;
}

.buttonPulse {
    animation: buttonPulseAnimate 0.2s ease-out 1;
}

@keyframes buttonPulseAnimate {
    100% {
	opacity: 0.1;
	transform: scale(1.2);
    }
}

.notFoundText {
  background-color: rgba(255,255,255,0.5);
  font-size: 1.8em;
  font-weight: 900;
  left: 0;
  letter-spacing: -3px;
  position: absolute;
  text-transform: uppercase;
  top: 290px;
  width: 100%;
  z-index: 2;
}

.notFound404 {
  font-size: 6em;
  font-weight: 800;
  left: 0;
  margin: -80px auto;
  position: absolute;
  width: 100%;
  z-index: 2;
}

.firstLetter {
  margin-left: -70px;
  transform: rotate(10deg);
}

.secondLetter {
  transform: rotate(-5deg);
}

.thirdLetter {
  margin-left: 70px;
  transform: rotate(20deg);
}

.notFound404Image.corgiImage {
  margin: 60px auto auto auto;
  opacity: 0.4;
}

.corgiImage {
  display: block;
  margin: auto;
  max-width: 128px;
  opacity: 0.6;
}

.videoContainer {
  margin-bottom: 8px;
  margin-top: 8px;
}

.henloTextContainer {
  text-align: left;
}

.henloHeader {
  display: inline;
}

.introBlock {
  margin-bottom: 16px;
}

.introBox {
    margin-bottom: 16px;
    margin-top: 16px;
}

.showDetailContainer {
    max-width: 768px;
    padding-bottom: 8px;
}

.headerCorner {
    position: absolute;
    right: 8px;
    top: 8px;
}

.programListNumbering {
    max-width: 48px;
    border-right: 1px solid rgba(0, 0, 0, .125);
}

.programCompact {
    text-align: left;
    max-width: 350px;
    display: inline-block;
}

.programNotes {
    max-width: 350px;
}

.programItem + .programItem {
    border-left: 1px solid rgba(0, 0, 0, .125);
    margin-left: 20px;
}

.scheduledProgram .programFilled {
    background-color: rgb(0, 255, 0, 0.05);
}

.scheduledProgram .programOverflow {
    background-color: rgb(255, 0, 0, 0.05);    
}

.programContact small,
.programContact a {
    display: block;
}

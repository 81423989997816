.eventDetailContainer {
    max-width: 768px;
    padding-bottom: 8px;
}

.purchaseTicketForm .input-group-text {
    min-width: 140px;
    justify-content: right;
}

.eventTicketInventoryIndicator {
  position: absolute;
  margin: 8px 16px;
  right: 0;
  top: 0
}

.subtotalRow {
    font-size: 1.2em;
    margin: 20px 0;
    text-align: right;
}

.subtotalRow .price {
    max-width: 128px;
}

.emailField.blur{
    filter: blur(4px);
}

.eventShareButtonContainer {
    display: grid!important;
}

.eventShareButton {
    margin-bottom: 8px;
}

.ticketLinkCell {
    word-break: break-all;
}

.eventPoster {
    position: relative;
}

.posterFloatingQR {
    float: left;
    position: absolute;
    z-index: 10;
    padding: 5px;
    color: #FFFFFF;
}

.eventTicketQR {
    height: auto;
    margin: 0 auto;
    width: 100%;
}

.hiddenButton {
    height: 12px;
    opacity: 0;
}

.eventCover {
    max-height: 512px;
}

.eventPreviewCard {
    width: 350px
}

.eventRecList .list_item {
    padding: 0 4px;
}

.ticketQRRow .ticketQRCol {
    display: flex;
    justify-content: center;
}

.ticketQRRow .ticketQRCol .ticketQRInfo {
    text-align: center;
}

.ticketQRRow .ticketQRCol .ticketQRInfo a {
    display: block;
}

.AppBody .eventDetailContainer .text-left {
    text-align: left;
}

.AppBody .eventDetailContainer .text-right {
    text-align: right;
}

.AppBody .eventDetailContainer .text-center {
    text-align: center;
}

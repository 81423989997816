.background {
    position: absolute;
    min-height: 100%;
    min-width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.background .logo,
.background .logo div {
    width: 100%;
    height: 100%;
    position: absolute;
    align-items: center;
    justify-content: center;
    display: flex;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    transform: scale(0.9);
    opacity: 0.5;
    background-size: contain;
}

.background .logo.pulse {
    animation: logoPulseAnimate 0.2s linear 1;
}

@keyframes logoPulseAnimate {
    0% {
	opacity: 1.0;
	transform: scale(0.93);	
    }
    75% {
	opacity: 0.6;
	transform: scale(0.91);
    }
    100% {
	opacity: 0.5;
	transform: scale(0.9);
    }
}

/**
 * TODO: make sure background still works when we move to
 * production react build + served by django
 */
.background .logo .tuan {
    transform: scale(0.8) translateY(40px);
    background-image: url("./tuan.png");
}

.background .logo .flatdog {
    transform: scale(0.7) translateY(10%);
    background-image: url("./flatdog.png");
}

.background .logo .palefire {
    transform: scale(0.6) translate(0px, 0px);
    background-image: url("./palefire.jpeg");
}

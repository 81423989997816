.gameMeter {
    display: flex;
    width: var(--side-w);
    flex-direction: column-reverse;
}

.maxedMeter .meterFrame {
    border-image: linear-gradient(140deg, rgb(255, 255, 100), rgb(199, 10, 114)) 1;
    animation: colorAnimate 1s linear infinite;
}

.meterFrame {
    /* add two border widths */
    width: calc(var(--meter-w) + 16px);
    height: calc(var(--meter-h) + 16px);
    
    border-radius: 4px;
    margin: 16px;
    border: 8px solid white;
    display: flex;
    z-index: 20;
}

.meterInner, .meterInnerBottom {
    max-width: var(--meter-w);
    height: calc(var(--score-cur) / var(--score-max) * var(--meter-h));
    width: 100%;
    align-self:flex-end;
    position:absolute;
    transition: height 1s ease;
}

.meterInner {
    background-image: linear-gradient(40deg, rgb(5, 124, 172), rgb(199, 10, 114));
    animation: colorAnimate 2s linear infinite;
    z-index: 5;
    mix-blend-mode: plus-lighter;
}

.meterInnerBottom {
    background: rgba(255, 255, 255, 0.5);
    z-index: 4;
}

@keyframes colorAnimate {
    100% {
	filter: hue-rotate(360deg);
    }
}

